import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import Loader from '../components/shared/Loader';
import { getConstants } from '../redux/actions/propertyActions';

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      await dispatch(getConstants());
      setLoader(false);
    })();
  }, [dispatch]);

  if (!isAuthenticated) return <Navigate to="/auth/login" replace />;

  if (loader) return <Loader />;

  return <Outlet />;
};

export default ProtectedRoute;
