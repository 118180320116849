import React from 'react';

import Home from '../components/home/home';
import Layout from '../layouts/Layout';

const Homepage = () => (
  <Layout id="homepage" header noFluid>
    <div className="static">
      <Home />
    </div>
  </Layout>
);

export default Homepage;
