import React, { useCallback, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { toast } from 'react-toastify';
import { Autoplay, EffectFade } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

import ContactUs from './contactUs';
import FAQs from './faqs';
import ChatBot from '../shared/ChatBot';

const whatsWizitcan = [
  {
    image: '/img/create-account.webp',
    title: 'Apply Online',
    description: 'Submit digital applications and check your status regularly.',
  },
  {
    image: '/img/Our Associates .webp',
    title: 'Our Associates',
    description:
      'We partner with trusted and selected vendors who really want to deliver solutions that our customers need.',
  },
  {
    image: '/img/Real-time Alerts.jpeg',
    title: 'Real-time Alerts',
    description: 'Results that are user-defined and allows you for the best match.',
  },
  {
    image: '/img/Quality Listing.webp',
    title: 'Quality Listing',
    description:
      'High quality listing to improve your experience to find, evaluate, compare, and make informed decisions.',
  },
];

const Home = () => {
  const rootRef = useRef(null);
  const history = useLocation();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const ownerTypeName = useSelector(state => state.auth.user.ownerTypeName);

  const wizitcanWorks = [
    {
      image: '/img/Apply Online .webp',
      title: 'Create An Account',
      description:
        'Sign up by creating an account with the required details to avail our services. The optimal feeding of correct information will help us fine-tune your desired results.',
      href: () => '/auth/register',
    },
    {
      image: 'img/list-property.jpeg',
      title: 'List your Property',
      description:
        "Welcome to our exclusive property listing platform tailored for incoming immigrants. Showcase your property's unique features and attract a wide range of tenants with our professional descriptions that highlight the best of what your property has to offer.",
      href: () => {
        if (!isAuthenticated) return '/auth/login';
        if (ownerTypeName === 'owner') return '/app/owner/property';
        toast.error('Please register as Property owner to list your property');
        return null;
      },
    },
    {
      image: '/img/Browse accomodation.webp',
      title: 'Browse Accomodation',
      description:
        'With various accommodation options, you can apply for a place that suits your requirements, interest, and budget in the area of your preference.',
      href: () => {
        if (!isAuthenticated) return '/auth/login';
        if (ownerTypeName === 'renter') return '/app/renter/property';
        toast.error('Please register as Renter to Browse Accomodation');
        return null;
      },
    },
  ];

  const navigateToId = useCallback(() => {
    if (history.hash) {
      const element = rootRef.current.children[history.hash.slice(1)];

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [history.hash]);

  useEffect(() => {
    setTimeout(navigateToId, 1000);
  }, [navigateToId]);

  const handelFBClick = () => {
    window.location.replace('https://www.facebook.com/wizitcansolutions');
  };

  const handleWorksClick = href => {
    if (!href) return;
    navigate(href);
  };

  return (
    <div className="home-page" ref={rootRef}>
      <div className="swiper-slider">
        <Swiper
          modules={[Autoplay, EffectFade]}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          onLoad={() => console.log('sd')}
        >
          <SwiperSlide>
            <img src="img/hero-1.webp" className="d-block carousel-img" alt="..." />
            <div className="carousel-caption text-light text-center">
              <h2 className="carousel_h2_1">ALWAYS DEVLIVERING WHAT YOU NEED! </h2>
              <h2 className="carousel_h2_2">
                Discover how we are helping you for your new journey in Canada.
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/carosal-2.webp" className="d-block carousel-img" alt="..." />
            <div className="carousel-caption">
              <h2 className="text-light text-center">
                Wizitcan provides immigrants to find homes, room, apartments or condos within local
                Canadian communities along with the possible job opportunities.
              </h2>
              <a href="#contact" className="btn cta-btn" type="submit">
                Contact Us
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="work container mx-auto" id="wizitcanworks">
        <h1 className="text-center title">
          How Wizitcan <span>works?</span>
        </h1>
        <div className="row g-sm-5 mt-3">
          {wizitcanWorks.map(({ title, description, image, href }) => (
            <div key={title} className="col-md-4 mt-5">
              <div className="card border-0">
                <img src={image} alt="myImage" />
                <div className="card-info py-3">
                  <h5 style={{ padding: 5 }}>{title}</h5>
                  <p className="mt-2">{description}</p>
                  <button
                    style={{ border: 0 }}
                    type="button"
                    onClick={() => handleWorksClick(href())}
                    className="stretched-link"
                  >
                    {' '}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container welcome">
        <h1 className="title text-center">
          <span>Welcome !</span>
        </h1>
        <div className="row g-sm-5">
          <div className="col-md-6 mt-5">
            <p className="welcome_p mt-5">
              Wizitcan Solutions is an online platform and an upcoming mobile application that
              connects immigrants who are going to Canada for University, work, and settlement and
              are looking for homestays and employment opportunities within the local communities.
              People already in Canada would also be able to provide specific on-site information
              about the area&apos;s locality and essentials that incoming immigrants would like to
              learn about. <br />
              <br />
              The platform would also include an open discussion and sharing board for everyone to
              support each other for any issues faced by the community...
            </p>
            <Link to="/about" className="btn cta-btn">
              Read More
            </Link>
          </div>
          <div className="col-md-6 mt-5">
            <img className="mt-5" src="img/Welcome .jpeg" alt="" />
          </div>
        </div>
      </div>

      <div className="container sowhat">
        <h1 className="title text-center">
          So <span>what’s</span> Wizitcan ?
        </h1>
        <div className="row g-sm-5 mt-4">
          {whatsWizitcan.map(({ title, description, image }) => (
            <div key={title} className="col-md-3 mt-5">
              <div className="card border-0">
                <img src={image} alt="myImage" />
                <div className="card-info py-3">
                  <h4>{title}</h4>
                  <p className="mt-2">{description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ContactUs />
      <FAQs />
      <hr />
      <ChatBot />
      <div className="container mb-2">
        <div className="row d-flex align-items-center">
          <div className="col-md-7 col-lg-8 text-center text-md-start">
            <div className="p-3">© Copyright 2023: wizitcansolutions.com</div>
          </div>

          <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
            <Link
              to="/"
              className=" btn-outline-light btn-floating m-1 mx-3 text-black"
              role="button"
              onClick={handelFBClick}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-facebook"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </span>
            </Link>
            <Link
              to="/"
              className=" btn-outline-light btn-floating m-1 mx-3 text-black"
              role="button"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
