import React, { useState, useEffect } from 'react';

import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Loader from '../../components/shared/Loader';
import Button from '../../components/UI/Button';
import Layout from '../../layouts/Layout';
import { getAllProperties, resetProperty } from '../../redux/actions/propertyActions';

const OwnerPropertyListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    (async () => {
      await dispatch(resetProperty());
      const { data } = await dispatch(
        getAllProperties({
          data: {
            pagination: false,
          },
        }),
      );
      setLoader(false);
      setProperties(data.data.docs);
    })();
  }, [dispatch]);

  if (loader) return <Loader />;

  return (
    <Layout id="property-page">
      <div className="table-info">
        <h2>All Properties</h2>
        <Button onClick={() => navigate('add')}>Add Property</Button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Location</th>
            <th>Last Step</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((cur, i) => (
            <tr key={cur._id}>
              <td>{i + 1}</td>
              <td>
                {cur.address2}, {cur.city}
              </td>
              <td>{cur.step}</td>
              <td>
                {cur.isCompleted ? (
                  <Badge bg="success">Completed</Badge>
                ) : (
                  <Badge bg="secondary">Pending</Badge>
                )}
              </td>
              <td className="actions">
                <span>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-bottom" className="listing-tooltip">
                        View Your Save Work
                      </Tooltip>
                    }
                  >
                    <Link className="text-white" to={`view/${cur._id}`}>
                      <i className="bi bi-eye" />
                    </Link>
                  </OverlayTrigger>
                </span>
                {!cur.isCompleted && (
                  <span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right" className="listing-tooltip">
                          Edit Your Actions
                        </Tooltip>
                      }
                    >
                      <Link className="text-white" to={`edit/${cur._id}`}>
                        <i className="bi bi-pencil" />
                      </Link>
                    </OverlayTrigger>
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Layout>
  );
};

export default OwnerPropertyListing;
