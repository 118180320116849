import { useBootstrapBreakpoints } from 'react-bootstrap/ThemeProvider';

export const useBreakpoints = () => {
  const breakpoints = useBootstrapBreakpoints();

  return breakpoints.reduce((acc, val) => {
    acc[val] = +getComputedStyle(document.body)
      .getPropertyValue(`--bs-breakpoint-${val}`)
      .slice(0, -2);
    return acc;
  }, {});
};
