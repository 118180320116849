import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormSelect from 'react-bootstrap/FormSelect';

const Select = ({ value, onChange, options, className = '' }) => (
  <FormSelect className={clsx(className ?? '', 'ui-select')} value={value} onChange={onChange}>
    {options.map(({ label, value: val, disabled = false }) => (
      <option key={val ?? Math.random()} value={val} disabled={disabled}>
        {label}
      </option>
    ))}
  </FormSelect>
);

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
};

export default Select;
