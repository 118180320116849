const constants = {
  listing: [],
  utilities: [],
  categories: [],
  furnishedList: [],
  leaseTerms: [],
  parkingTypes: [],
  parkingSpots: [],
  safetyFeatures: [],
  buildingFeatures: [],
  unitFeatures: [],
  nearbyPlaces: [],
};

const initialState = {
  constants,
  property: {
    listing: {},
  },
};

export const floorState = {
  bedroom: 1,
  bathroom: 1,
  rent: '',
  unitSize: '',
  availabilityDate: null,
};

export const modifyFloorPlans = floorPlans =>
  floorPlans.map(cur =>
    Object.entries(cur).reduce(
      (acc, [k, v]) => {
        if (Object.keys(floorState).includes(k)) {
          acc[k] =
            k === 'availabilityDate'
              ? new Date(v ?? Date.now()).toLocaleDateString().split('/').reverse().join('-')
              : v;
        }
        return acc;
      },
      {
        id: cur._id,
      },
    ),
  );

const propertyReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case 'CONSTANTS':
      return {
        ...state,
        constants: payload,
      };
    case 'MANAGE_PROPERTY':
    case 'GET_PROPERTY':
      return {
        ...state,
        property: {
          ...payload,
          builtYear: payload.builtYear ?? new Date().getFullYear(),
          isPetFriendly: payload.isPetFriendly ?? false,
          isDoingSmoking: payload.isDoingSmoking ?? false,
          description: payload.description ?? '',
          estimatedTransitScore: payload.estimatedTransitScore ?? '',
          floorPlans: payload.floorPlans.length
            ? modifyFloorPlans(payload.floorPlans)
            : [{ ...floorState, id: Math.random().toString() }],
          createdAt: undefined,
          updatedAt: undefined,
        },
      };
    case 'RESET_PROPERTY':
      return {
        ...state,
        property: initialState.property,
      };
    case 'ADD_PROPERTY_IMAGE':
    case 'DELETE_PROPERTY_IMAGE':
      return {
        ...state,
        property: {
          ...state.property,
          images: payload,
        },
      };
    default:
      return state;
  }
};

export default propertyReducer;
