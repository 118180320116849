import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const Input = ({ value, onChange, ...rest }) => (
  <Form.Control
    {...rest}
    className={clsx(rest.className, 'ui-input')}
    value={value}
    onChange={onChange}
  />
);

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Input;
