const endpoints = {
  signup: {
    method: 'POST',
    url: '/auth/signup',
  },
  login: {
    method: 'POST',
    url: '/auth/login',
  },
  socialAuth: {
    method: 'POST',
    url: '/auth/social-auth',
  },
  me: {
    method: 'POST',
    url: '/profile/me',
  },
  getConstants: {
    method: 'GET',
    url: '/:type/property/constants',
  },
  manageProperty: {
    method: 'POST',
    url: '/owner/property/manage',
  },
  getProperty: {
    method: 'GET',
    url: '/:type/property/lists/:id',
  },
  getAllProperties: {
    method: 'POST',
    url: '/:type/property/lists',
  },
  addPropertyImage: {
    method: 'POST',
    url: '/owner/property/image/create',
  },
  deletePropertyImage: {
    method: 'POST',
    url: '/owner/property/image/delete',
  },
  createContact: {
    method: 'POST',
    url: '/contact/create',
  },
  createChatBotDetails: {
    method: 'POST',
    url: '/chat-bot-details/create',
  },
  manageShortlist: {
    method: 'POST',
    url: '/renter/shortlist/manage',
  },
  createInquiry: {
    method: 'POST',
    url: '/inquiry/create',
  },
  getAllShortlists: {
    method: 'POST',
    url: '/renter/shortlist/lists',
  },
  getAllPropertyCities: {
    method: 'POST',
    url: '/renter/property/cities',
  },
  getAllHotPics: {
    method: 'POST',
    url: '/renter/shortlist/hot-pics',
  },
};

export default endpoints;
