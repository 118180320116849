import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Typography from '../UI/Typography';

const PropertyFacilities = ({
  isPetFriendly,
  isDoingSmoking,
  unitFeatures,
  floorPlans,
  description = '',
}) => {
  const constants = useSelector(state => state.property.constants);
  const features = unitFeatures.slice(0, 3);

  const options = [
    {
      id: 1,
      className: 'description',
      inline: false,
      text: description ?? '',
      cond: true,
    },
    ...features.map(feature => ({
      id: feature.selectedId,
      className: 'unit-features',
      inline: true,
      text: (constants.unitFeatures ?? []).find(c => c._id === feature.selectedId)?.label ?? '',
      cond: true,
    })),
    {
      id: 3,
      className: 'block-space',
      block: true,
    },
    {
      id: 4,
      className: 'floor-plan',
      inline: true,
      text: `${floorPlans.length} Options available`,
      icon: 'houses',
      cond: floorPlans.length > 1,
    },
    {
      id: 5,
      className: 'pet-friendly',
      inline: true,
      text: 'Pet Friendly',
      svg: '/pet.svg',
      cond: isPetFriendly,
    },
    {
      id: 6,
      className: 'smoking',
      inline: true,
      text: 'Smoking',
      svg: '/smoking.svg',
      cond: isDoingSmoking,
    },
  ];

  return (
    <div className="facilities">
      {options.map(({ id, className, block, inline, cond, ...rest }) => {
        if (!cond && !block) return null;

        return (
          <div key={id} className={clsx('gray-block', className, { inline, block })}>
            {block ? null : <Typography {...rest} subtitle />}
          </div>
        );
      })}
    </div>
  );
};

PropertyFacilities.propTypes = {
  isPetFriendly: PropTypes.bool.isRequired,
  isDoingSmoking: PropTypes.bool.isRequired,
  unitFeatures: PropTypes.instanceOf(Array).isRequired,
  floorPlans: PropTypes.instanceOf(Array).isRequired,
  description: PropTypes.string,
};

export default PropertyFacilities;
