import React from 'react';

import ReactDOM from 'react-dom/client';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
