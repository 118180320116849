import React from 'react';

import PropTypes from 'prop-types';

import Checkbox from '../UI/Checkbox';
import Input from '../UI/Input';

const FormList = ({
  parent,
  reduxState,
  state,
  setState,
  type = 'checkbox',
  selectedOnly = false,
}) => {
  const isCheckbox = type === 'checkbox';
  const parentObj = (isCheckbox ? state : [state]).find(cur => parent._id === cur.selectedId);

  const handleCheckbox = (value, parentId) => {
    const findState = parentId ? reduxState.find(cur => cur._id === parentId).children : reduxState;
    const data = findState.find(cur => cur._id === value);
    const obj = {
      selectedId: data._id,
      hasChildren: data.hasChildren,
      isOther: data.isOther,
      otherText: null,
      children: [],
    };

    const selectedData = arr =>
      arr.map(cur => cur.selectedId).includes(value)
        ? arr.filter(cur => cur.selectedId !== value)
        : [...arr, obj];

    // ORDER MATTERS
    setState(prev => {
      if (!isCheckbox) {
        if (!parentId) return obj;
        return { ...prev, children: selectedData(prev.children) };
      }

      if (!parentId) return selectedData(prev);

      return prev.map(cur =>
        cur.selectedId === parentId
          ? {
              ...cur,
              children: selectedData(cur.children),
            }
          : cur,
      );
    });
  };

  const handleOther = (value, parentId, childId) => {
    const isChild = parentId && childId;

    if (!isChild) {
      setState(prev => {
        if (!isCheckbox) return { ...prev, otherText: value };

        return prev.map(cur => (cur.selectedId === parentId ? { ...cur, otherText: value } : cur));
      });
      return;
    }

    setState(prev => ({
      ...prev,
      children: prev.children.map(cur =>
        cur.selectedId === childId ? { ...cur, otherText: value } : cur,
      ),
    }));
  };

  if (selectedOnly && !parentObj) return null;

  return (
    <Checkbox
      type={type}
      label={
        selectedOnly && parent.isOther && parentObj?.isOther
          ? parentObj?.otherText ?? ''
          : parent.label
      }
      value={parent._id}
      checked={!!parentObj}
      onChange={e => handleCheckbox(e.target.value, null)}
      other={parent.isOther}
    >
      {!selectedOnly && parent.isOther && parentObj?.isOther && (
        <div className="recursive-other">
          <Input
            className="form-parent-input"
            value={parentObj?.otherText ?? ''}
            onChange={e => handleOther(e.target.value, parent._id)}
            placeholder="Please specify"
          />
        </div>
      )}

      {parent.hasChildren && parentObj?.hasChildren && (
        <div className="recursive-children">
          {parent.children.map(child => {
            const getChildObj = () => {
              if (!isCheckbox) {
                if (!state.children || !Object.keys(state).length) return undefined;
                return state.children.find(cur => child._id === cur.selectedId);
              }

              const childParentObj = state.find(cur => cur.selectedId === parent._id);
              return childParentObj
                ? childParentObj.children.find(cur => child._id === cur.selectedId)
                : childParentObj;
            };

            const childObj = getChildObj();

            if (selectedOnly && !childObj) return null;

            return (
              <Checkbox
                child
                type="checkbox"
                label={
                  selectedOnly && !isCheckbox && child.isOther && childObj?.isOther
                    ? childObj?.otherText ?? ''
                    : child.label
                }
                value={child._id}
                checked={!!childObj}
                onChange={e => handleCheckbox(e.target.value, parent._id)}
                other={child.isOther}
                key={child._id}
              >
                {!selectedOnly && !isCheckbox && child.isOther && childObj?.isOther && (
                  <Input
                    className="form-children-input"
                    value={childObj?.otherText ?? ''}
                    onChange={e => handleOther(e.target.value, parent._id, child._id)}
                    placeholder="Please specify"
                  />
                )}
              </Checkbox>
            );
          })}
        </div>
      )}
    </Checkbox>
  );
};

const RecursiveFormList = ({ reduxState, type = 'checkbox', ...rest }) => (
  <div className="recursive-root">
    {reduxState.map(parent => (
      <FormList {...rest} key={parent._id} parent={parent} reduxState={reduxState} type={type} />
    ))}
  </div>
);

const constants = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool.isRequired,
  isOther: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isOther: PropTypes.bool.isRequired,
    }),
  ),
});

const common = PropTypes.shape({
  selectedId: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool.isRequired,
  isOther: PropTypes.bool.isRequired,
  otherText: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      selectedId: PropTypes.string.isRequired,
      isOther: PropTypes.bool.isRequired,
      otherText: PropTypes.string,
    }),
  ),
});

const propTypes = {
  reduxState: PropTypes.arrayOf(constants).isRequired,
  state: PropTypes.oneOfType([common, PropTypes.arrayOf(common)]).isRequired,
  setState: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  selectedOnly: PropTypes.bool,
};

FormList.propTypes = {
  ...propTypes,
  parent: constants.isRequired,
};

RecursiveFormList.propTypes = propTypes;

export default RecursiveFormList;
