import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import RecursiveFormList from '../shared/RecursiveFormList';
import Input from '../UI/Input';
import Typography from '../UI/Typography';

const PropertyStep3 = ({ Buttons, viewOnly }) => {
  const {
    safetyFeatures: safetyFeaturesData,
    buildingFeatures: buildingFeaturesData,
    unitFeatures: unitFeaturesData,
    nearbyPlaces: nearbyPlacesData,
  } = useSelector(state => state.property.constants);
  const property = useSelector(state => state.property.property);
  const [safetyFeatures, setSafetyFeatures] = useState(property.safetyFeatures);
  const [buildingFeatures, setBuildingFeatures] = useState(property.buildingFeatures);
  const [unitFeatures, setUnitFeatures] = useState(property.unitFeatures);
  const [nearbyPlaces, setNearbyPlaces] = useState(property.nearbyPlaces);
  const [estimatedTransitScore, setEstimatedTransitScore] = useState(
    property.estimatedTransitScore,
  );

  return (
    <Fragment>
      <div className="step step-3" style={{ pointerEvents: viewOnly ? 'none' : 'visible' }}>
        <div className="step-wrapper">
          <Typography text="Features / Amenities" mb={1} />
          <Typography text="Select the features that apply to your property." subtitle mb={0} />
        </div>
        <div className="step-wrapper">
          <Typography text="Safety Features" icon="exclamation-triangle" />
          <RecursiveFormList
            reduxState={safetyFeaturesData}
            state={safetyFeatures}
            setState={setSafetyFeatures}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Building Features" icon="building" />
          <RecursiveFormList
            reduxState={buildingFeaturesData}
            state={buildingFeatures}
            setState={setBuildingFeatures}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Unit Features" icon="hr" />
          <RecursiveFormList
            reduxState={unitFeaturesData}
            state={unitFeatures}
            setState={setUnitFeatures}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Nearby Places" icon="shop" />
          <RecursiveFormList
            reduxState={nearbyPlacesData}
            state={nearbyPlaces}
            setState={setNearbyPlaces}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Estimated transit score" icon="list-ol" />
          <Input
            value={estimatedTransitScore}
            onChange={e => setEstimatedTransitScore(e.target.value)}
          />
        </div>
      </div>

      <Buttons
        state={{
          safetyFeatures,
          buildingFeatures,
          unitFeatures,
          nearbyPlaces,
          estimatedTransitScore,
        }}
      />
    </Fragment>
  );
};

PropertyStep3.propTypes = {
  Buttons: PropTypes.node.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default PropertyStep3;
