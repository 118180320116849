import React, { Fragment } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ownerTypes } from '../../constants';
import { logout } from '../../redux/actions/authActions';

const Header = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);

  return (
    <Navbar className="layout-header" variant="dark" expand="sm" fixed="top">
      <Container fluid>
        <Navbar.Brand to="/" as={Link}>
          <img src="/logo.svg" width="200" className="d-inline-block align-top" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
            {isAuthenticated ? (
              <Fragment>
                <Nav.Link to="/about" as={Link}>
                  About
                </Nav.Link>
                <Nav.Link to="/app" as={Link}>
                  Property
                </Nav.Link>
                {user.ownerType === ownerTypes.RENTER && (
                  <Nav.Link to="/app/renter/property/shortlists" as={Link}>
                    Shortlists
                  </Nav.Link>
                )}
                <Nav.Link onClick={() => dispatch(logout())}>Logout</Nav.Link>
              </Fragment>
            ) : (
              <Fragment>
                <Nav.Link to="/about" as={Link}>
                  About
                </Nav.Link>
                <Nav.Link className="header-btn" to="/auth/register" as={Link}>
                  Sign Up
                </Nav.Link>
                <Nav.Link className="header-btn" to="/auth/login" as={Link}>
                  Sign In
                </Nav.Link>
              </Fragment>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
