import React, { useState, Fragment } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getCityOptions, getCountryOptions, getStateOptions } from '../shared/country-state-city';
import RecursiveFormList from '../shared/RecursiveFormList';
import Input from '../UI/Input';
import Select from '../UI/Select';
import Typography from '../UI/Typography';

const PropertyStep1 = ({ Buttons, viewOnly }) => {
  const { listing: listingData } = useSelector(state => state.property.constants);
  const property = useSelector(state => state.property.property);
  const [listing, setListing] = useState(property.listing);
  const [address1, setAddress1] = useState(property.address1 ?? '');
  const [address2, setAddress2] = useState(property.address2 ?? '');
  const [country, setCountry] = useState(property.country ?? '');
  const [state, setState] = useState(property.state ?? '');
  const [city, setCity] = useState(property.city ?? '');
  const [zipcode, setZipcode] = useState(property.zipcode ?? '');

  return (
    <Fragment>
      <div className="step step-1" style={{ pointerEvents: viewOnly ? 'none' : 'visible' }}>
        <div className="step-wrapper">
          <Typography text="List for free" icon="buildings" mb={1} />
          <Typography text="Select the category that matches your listing" subtitle />
          <RecursiveFormList
            type="radio"
            reduxState={listingData}
            state={listing}
            setState={setListing}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Property Location" icon="geo-alt" mb={1} />
          <Typography
            text="Please provide your property location below with the postal code"
            subtitle
            mb={1}
          />

          <Typography text="Address 1" subtitle mb={0.5} mt={1} />
          <Input value={address1} onChange={e => setAddress1(e.target.value)} />

          <Typography text="Address 2" subtitle mb={0.5} mt={1} />
          <Input value={address2} onChange={e => setAddress2(e.target.value)} />

          <Typography text="Country" subtitle mb={0.5} mt={1} />
          <Select
            value={country}
            onChange={e => {
              setCountry(e.target.value);
              setState('');
              setCity('');
            }}
            options={getCountryOptions()}
          />

          <Typography text="State" subtitle mb={0.5} mt={1} />
          <Select
            value={state}
            onChange={e => {
              setState(e.target.value);
              setCity('');
            }}
            options={getStateOptions(country)}
          />

          <Typography text="City" subtitle mb={0.5} mt={1} />
          <Select
            value={city}
            onChange={e => setCity(e.target.value)}
            options={getCityOptions(country, state)}
          />

          <Typography text="Postal Code" subtitle mb={0.5} mt={1} />
          <Input value={zipcode} onChange={e => setZipcode(e.target.value)} />
        </div>
      </div>
      <Buttons state={{ listing, address1, address2, country, state, city, zipcode }} />
    </Fragment>
  );
};

PropertyStep1.propTypes = {
  Buttons: PropTypes.node.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default PropertyStep1;
