import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

const data = [
  {
    question: 'How do I get access to search your rental listing?',
    answer:
      'To access our listings and other unit features, you need to create an account using your email address and a password. Once your account is set up, you will have access to a pool of listings that will match your preference.',
  },
  {
    question: 'How much can I expect to pay?',
    answer:
      'Our rental listing page shows different price ranges and averages for everything from a single room in shared housing unit to a condo or an apartment. If there is any change in the price, it will be reflected on the listing page.',
  },
  {
    question:
      'If I am renting with a roommate/friend, should we prepare two different applications?',
    answer:
      'No, you do not need to prepare two different applications. A single person can start the application process and have the lease executed, adding the second one as a roommate. Make sure you provide all the information about your roommate to the landlord beforehand and have all the documentation ready.',
  },
  {
    question: 'When should I look for fall housing?',
    answer:
      'The listings are on the page throughout the year. Since fall is a major period when immigrants and students move to Canada, you can start looking for the units in June and mid-August to have ample time for your settlement and make the necessary arrangements.',
  },
];

const FAQs = () => (
  <div className="container my-5">
    <h1 className="text-center title">
      <span>FAQs</span>
    </h1>
    <div className="faq-container">
      <Accordion defaultActiveKey="0">
        {data.map(({ question, answer }, index) => (
          <Accordion.Item key={question} className="mt-3" eventKey={index.toString()}>
            <Accordion.Button className="rounded fs-3 accordion-btn active-faq">
              {question}
            </Accordion.Button>
            <Accordion.Body>
              <p className="fs-4">{answer}</p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  </div>
);

export default FAQs;
