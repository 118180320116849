import axios from 'axios';
import { toast } from 'react-toastify';

import endpoints from './endpoints';
import { logout } from '../redux/actions/authActions';
import store from '../redux/store';

const { REACT_APP_BASE_URL, REACT_APP_AUTH_HEADER_NAME: HEADER_NAME } = process.env;
class ApiUtils {
  // eslint-disable-next-line no-restricted-syntax
  constructor(appendToken = true) {
    this.axios = axios.create({ baseURL: REACT_APP_BASE_URL });

    this.axios.interceptors.request.use(
      config => {
        const myConfig = { ...config };

        myConfig.url = Object.entries(config.urlParams || {}).reduce((acc, [k, v]) => {
          // eslint-disable-next-line no-param-reassign
          acc = acc.replace(`:${k}`, v);
          return acc;
        }, myConfig.url);

        if (appendToken) {
          const { auth } = store.getState();
          if (auth.isAuthenticated) myConfig.headers[HEADER_NAME] = `Bearer ${auth.token}`;
        }
        return myConfig;
      },
      error => {
        console.debug('Request Error', error);
        return Promise.reject(error);
      },
    );

    this.axios.interceptors.response.use(
      res => ({ ...res }),
      error => {
        console.debug('Response Error', error);

        if (error.response.status === 401) {
          store.dispatch(logout());
        }

        if (!error.response.data.hideToast) {
          toast.error(error.response.data.message, { autoClose: 3000 });
        }

        return Promise.reject(error);
      },
    );
  }

  signup = data => this.axios({ ...endpoints.signup, ...data });

  login = data => this.axios({ ...endpoints.login, ...data });

  socialAuth = data => this.axios({ ...endpoints.socialAuth, ...data });

  loadUser = data => this.axios({ ...endpoints.me, ...data });

  getConstants = data => this.axios({ ...endpoints.getConstants, ...data });

  manageProperty = data => this.axios({ ...endpoints.manageProperty, ...data });

  getProperty = data => this.axios({ ...endpoints.getProperty, ...data });

  getAllProperties = data => this.axios({ ...endpoints.getAllProperties, ...data });

  addPropertyImage = data => this.axios({ ...endpoints.addPropertyImage, ...data });

  deletePropertyImage = data => this.axios({ ...endpoints.deletePropertyImage, ...data });

  createContact = data => this.axios({ ...endpoints.createContact, ...data });

  createChatBotDetails = data => this.axios({ ...endpoints.createChatBotDetails, ...data });

  manageShortlist = data => this.axios({ ...endpoints.manageShortlist, ...data });

  createInquiry = data => this.axios({ ...endpoints.createInquiry, ...data });

  getAllShortlists = data => this.axios({ ...endpoints.getAllShortlists, ...data });

  getAllPropertyCities = data => this.axios({ ...endpoints.getAllPropertyCities, ...data });

  getAllHotPics = data => this.axios({ ...endpoints.getAllHotPics, ...data });
}

export default ApiUtils;
