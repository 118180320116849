import ApiUtils from '../../api/APIUtils';

const api = new ApiUtils();

export const getConstants = () => async (dispatch, getState) => {
  try {
    const res = await api.getConstants({
      urlParams: {
        type: getState().auth.user.ownerTypeName,
      },
    });

    dispatch({
      type: 'CONSTANTS',
      payload: res.data.data,
    });

    return res;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const manageProperty = data => async dispatch => {
  try {
    const res = await api.manageProperty(data);

    dispatch({
      type: 'MANAGE_PROPERTY',
      payload: res.data.data,
    });

    return res;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const getAllProperties = (data, shortlists) => async (dispatch, getState) => {
  const call = shortlists ? 'getAllShortlists' : 'getAllProperties';

  try {
    return await api[call]({
      ...data,
      data: {
        ...(data.data ?? {}),
        sort: -1,
        sortBy: 'createdAt',
      },
      urlParams: {
        type: getState().auth.user.ownerTypeName,
      },
    });
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const getProperty = id => async (dispatch, getState) => {
  try {
    const res = await api.getProperty({
      urlParams: {
        id,
        type: getState().auth.user.ownerTypeName,
      },
    });

    dispatch({
      type: 'GET_PROPERTY',
      payload: res.data.data,
    });

    return res;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const resetProperty = () => async dispatch => {
  dispatch({ type: 'RESET_PROPERTY' });
  return true;
};

export const addPropertyImage = data => async dispatch => {
  try {
    const res = await api.addPropertyImage(data);

    dispatch({
      type: 'ADD_PROPERTY_IMAGE',
      payload: res.data.data,
    });

    return res;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const deletePropertyImage = data => async dispatch => {
  try {
    const res = await api.deletePropertyImage(data);

    dispatch({
      type: 'DELETE_PROPERTY_IMAGE',
      payload: res.data.data,
    });

    return res;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const manageShortlist = data => async () => {
  try {
    return await api.manageShortlist(data);
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const createInquiry = data => async () => {
  try {
    return await api.createInquiry(data);
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const getAllPropertyCities = () => async () => {
  try {
    return await api.getAllPropertyCities({});
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};

export const getAllHotPics = () => async data => {
  try {
    return await api.getAllHotPics(data);
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};
