import React, { useState } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropertyFacilities from './PropertyFacilities';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { manageShortlist } from '../../redux/actions/propertyActions';
import Button from '../UI/Button';
import Typography from '../UI/Typography';

const PropertyCard = ({
  vertical,
  _id: id,
  images,
  address1,
  address2,
  city,
  floorPlans,
  isShortlisted: isDBShortlisted,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isShortlisted, setShortlist] = useState(isDBShortlisted);

  const image = images[0] ? images[0].url : '/test.webp';
  const [{ rent: price }] = floorPlans.slice().sort((a, b) => a.rent - b.rent);

  useUpdateEffect(() => {
    (async () => {
      await dispatch(
        manageShortlist({
          data: { id, isAdded: isShortlisted },
        }),
      );
    })();
  }, [dispatch, id, isShortlisted]);

  const handleViewMore = () => {
    const path = `/app/renter/property/${id}`;
    navigate(path, { relative: 'path' });
  };

  const icon = (
    <i
      role="presentation"
      className={`icon bi ${isShortlisted ? 'bi-heart-fill' : 'bi-heart'}`}
      onClick={() => setShortlist(bool => !bool)}
    />
  );

  return (
    <div
      role="presentation"
      className={clsx('property-card', { vertical })}
      onClick={() => (vertical ? handleViewMore() : null)}
    >
      <div className="image-block">
        <img src={image} alt="property" />
      </div>
      <div className="content-block">
        <div className="name-block">
          <Typography text={`${address2}, ${city}`} subtitle mb={0} />
          {!vertical && icon}
        </div>
        <div className="area">
          <Typography text={`${address1}, ${city}`} icon="geo-alt" subtitle mb={0} />
        </div>
        <PropertyFacilities {...props} floorPlans={floorPlans} />
        <div className="price-block">
          <div className="price-text-block">
            <span className="price-text">From: </span>
            <span className="price">${price}/month</span>
          </div>
          {!vertical && (
            <Button size="sm" onClick={handleViewMore}>
              View More
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

PropertyCard.propTypes = {
  vertical: PropTypes.bool.isRequired,
  _id: PropTypes.string.isRequired,
  images: PropTypes.instanceOf(Array).isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  floorPlans: PropTypes.instanceOf(Array).isRequired,
  isShortlisted: PropTypes.bool.isRequired,
};

export default PropertyCard;
