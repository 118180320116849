import React from 'react';

import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ownerTypes } from './constants';
import DeciderRoute from './outlets/DeciderRoute';
import NonProtectedRoute from './outlets/NonProtectedRoute';
import PermissionsRoute from './outlets/PermissionsRoute';
import ProtectedRoute from './outlets/ProtectedRoute';
import About from './pages/About';
import Auth from './pages/Auth';
import ComingSoon from './pages/ComingSoon';
import ContactSuccess from './pages/ContactSuccess';
import Homepage from './pages/Homepage';
import ManageProperty from './pages/owner/ManageProperty';
import OwnerPropertyListing from './pages/owner/OwnerPropertyListing';
import PropertyDetail from './pages/renter/PropertyDetail';
import RenterProperty from './pages/renter/RenterProperty';
import RenterPropertyListing from './pages/renter/RenterPropertyListing';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/coming-soon',
    element: <ComingSoon />,
  },
  {
    path: '/contact-success/:name',
    element: <ContactSuccess />,
  },
  {
    path: '/auth/:type',
    element: <NonProtectedRoute />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
    ],
  },
  {
    path: '/app',
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <DeciderRoute />,
      },
      {
        index: true,
        element: <Navigate to="/about" />,
      },
      {
        path: 'owner',
        element: <PermissionsRoute ownerType={ownerTypes.OWNER} />,
        children: [
          {
            index: true,
            element: <Navigate to="/app/owner/property" replace />,
          },
          {
            path: 'property',
            element: <OwnerPropertyListing />,
          },
          {
            path: 'property/add',
            element: <ManageProperty mode="add" />,
          },
          {
            path: 'property/edit/:id',
            element: <ManageProperty mode="edit" />,
          },
          {
            path: 'property/view/:id',
            element: <ManageProperty mode="view" />,
          },
        ],
      },
      {
        path: 'renter',
        element: <PermissionsRoute ownerType={ownerTypes.RENTER} />,
        children: [
          {
            index: true,
            element: <Navigate to="/app/renter/property" replace />,
          },
          {
            path: 'property',
            element: <RenterProperty />,
          },
          {
            path: 'property/listing',
            element: <RenterPropertyListing />,
          },
          {
            path: 'property/shortlists',
            element: <RenterPropertyListing shortlists />,
          },
          {
            path: 'property/:id',
            element: <PropertyDetail />,
          },
        ],
      },
    ],
  },
]);

export default router;
