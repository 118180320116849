import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { ownerTypes } from '../constants';

const PermissionsRoute = ({ ownerType: ownerTypeProp }) => {
  const ownerType = useSelector(state => state.auth.user.ownerType);

  if (ownerTypeProp !== ownerType) return <Navigate to="/app" replace />;
  return <Outlet />;
};

PermissionsRoute.propTypes = {
  ownerType: PropTypes.oneOf(Object.values(ownerTypes)).isRequired,
};

export default PermissionsRoute;
