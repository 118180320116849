import { ownerTypes } from '../../constants';

const initialState = {
  isAuthenticated: false,
  token: null,
  user: {},
};

const AuthReducer = (state = initialState, action = {}) => {
  const { type, payload, token } = action;

  switch (type) {
    case 'EXISTING_USER':
      return {
        ...state,
        isAuthenticated: true,
        token,
        user: {
          ...payload,
          ownerTypeName: payload.ownerType === ownerTypes.OWNER ? 'owner' : 'renter',
        },
      };
    case 'NEW_USER':
    case 'AUTH_FAILED':
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;
