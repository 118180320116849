import React, { useEffect, useState, useRef } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

import ApiUtils from '../../api/APIUtils';

const api = new ApiUtils();

export const theme = {
  background: '#fff',
  headerBgColor: '#295845',
  headerFontColor: '#fff',
  headerFontSize: '20px',
  botBubbleColor: '#295845dc',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#000',
};

const renderTooltip = props => (
  <Tooltip id="chatbot-tooltip" {...props} hasDoneInitialMeasure>
    Hi, how can I help?
  </Tooltip>
);

// eslint-disable-next-line react/prop-types
const FloatingIcon = ({ show, setShow }) => (
  <OverlayTrigger show={show} placement="top" overlay={renderTooltip}>
    <img role="presentation" onClick={() => setShow(false)} src="/chatbot.png" alt="chatbot" />
  </OverlayTrigger>
);

const ChatBot = () => {
  const [show, setShow] = useState(false);
  const timeout = useRef({ done: false });
  const TIMEOUT = 3000;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (!timeout.current.done) {
        setShow(true);
        timeout.current.done = true;
      }
    });
  }, []);

  useEffect(() => {
    if (show) setTimeout(() => setShow(false), TIMEOUT);
  }, [show]);

  const handleEnd = async ({ steps }) => {
    const { name, email, number, message, homeOwner, place } = steps;
    await api.createChatBotDetails({
      data: {
        name: name.value,
        email: email.value,
        number: number.value,
        message: message.value,
        homeOwner: homeOwner.value,
        place: place.value,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <ReactChatBot
        floating
        className="wizitcan-chatbot"
        floatingIcon={<FloatingIcon show={show} setShow={setShow} />}
        placeholder="..."
        handleEnd={handleEnd}
        botAvatar="/chatbot.png"
        steps={[
          {
            id: 'hello',
            message: 'Hello',
            trigger: 'ask-name',
          },
          {
            id: 'ask-name',
            message: 'What is your name?',
            trigger: 'name',
          },
          {
            id: 'name',
            user: true,
            trigger: 'name-bot',
            placeholder: 'Type the name ...',
            validator: value => {
              if (!value) return 'Value is required';
              return true;
            },
          },
          {
            id: 'name-bot',
            message: 'Hi {previousValue}, nice to meet you!',
            trigger: 'ask-homeOwner',
          },
          {
            id: 'ask-homeOwner',
            message: 'Are you a homeowner?',
            trigger: 'homeOwner',
          },
          {
            id: 'homeOwner',
            options: [
              { value: true, label: 'Yes', trigger: 'ask-place' },
              { value: false, label: 'No', trigger: 'ask-place' },
            ],
          },
          {
            id: 'ask-place',
            message: 'Are you looking for a place?',
            trigger: 'place',
          },
          {
            id: 'place',
            options: [
              { value: true, label: 'Yes', trigger: 'ask-email' },
              { value: false, label: 'No', trigger: 'ask-email' },
            ],
          },
          {
            id: 'ask-email',
            message: 'What is your email id?',
            trigger: 'email',
          },
          {
            id: 'email',
            user: true,
            trigger: 'ask-number',
            placeholder: 'Type the email ...',
            validator: value => {
              const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!value) return 'Value is required';
              if (!regex.test(value)) return 'Value must be email';
              return true;
            },
          },
          {
            id: 'ask-number',
            message: 'What is your number?',
            trigger: 'number',
          },
          {
            id: 'number',
            user: true,
            trigger: 'ask-message',
            placeholder: 'Type the number ...',
            validator: value => {
              const regex = /^\d+$/;
              if (!value) return 'Value is required';
              if (!regex.test(value)) return 'Value must be number';
              return true;
            },
          },
          {
            id: 'ask-message',
            message: 'What is your message?',
            trigger: 'message',
          },
          {
            id: 'message',
            user: true,
            trigger: 'end',
            placeholder: 'Type the message ...',
            validator: value => {
              if (!value) return 'Value is required';
              return true;
            },
          },
          {
            id: 'end',
            message:
              'Thank you for your response. We look forward to connecting with you soon via email to address your inquiries.',
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};

export default ChatBot;
