import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const Checkbox = ({
  type,
  label,
  value,
  checked,
  onChange,
  id = value,
  children = null,
  child = false,
  disabled = false,
  other = false,
}) => (
  <Form.Check
    className={clsx('ui-checkbox', {
      active: checked,
      disabled,
      'form-children': child,
      'form-other': other,
    })}
  >
    <div className="ui-checkbox-internal">
      <Form.Check.Input
        id={id}
        type={type}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        required
      />
      <Form.Check.Label htmlFor={id}>{label}</Form.Check.Label>
    </div>
    {children}
  </Form.Check>
);

Checkbox.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  child: PropTypes.bool,
  disabled: PropTypes.bool,
  other: PropTypes.bool,
};

export default Checkbox;
