import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PropertyStep1 from '../../components/property/step1';
import PropertyStep2 from '../../components/property/step2';
import PropertyStep3 from '../../components/property/step3';
import PropertyStep4 from '../../components/property/step4';
import Loader from '../../components/shared/Loader';
import Button from '../../components/UI/Button';
import Layout from '../../layouts/Layout';
import { getProperty, manageProperty } from '../../redux/actions/propertyActions';

const ManageProperty = ({ mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStep = searchParams.get('step');
  const id = mode === 'add' ? undefined : params.id;

  const constants = { minStep: 1, maxStep: 4 };
  const steps = {
    1: PropertyStep1,
    2: PropertyStep2,
    3: PropertyStep3,
    4: PropertyStep4,
  };

  const getStep = () => {
    if (mode !== 'edit') return 1;

    if (searchStep) {
      if (constants.maxStep < +searchStep) return constants.minStep;
      return +searchStep;
    }
    return constants.minStep;
  };

  const [step, setStep] = useState(getStep());
  const [loader, setLoader] = useState(mode !== 'add');
  const [previousLoading, setPreviousLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (mode === 'add') return;

      const res = await dispatch(getProperty(id));
      if (!res) {
        navigate('/app');
        return;
      }
      if (mode === 'edit') setStep(res.data.data.step);
      setLoader(false);
    })();
  }, [dispatch, id, mode, navigate]);

  const handleHome = () => navigate('/app');

  const handlePrevious = async state => {
    if (mode === 'view') {
      setStep(prev => prev - 1);
      return;
    }

    const previous = step - 1;

    setPreviousLoading(true);
    const res = await dispatch(
      manageProperty({
        data: {
          id,
          data: {
            ...state,
            step: previous,
            isCompleted: false,
          },
        },
      }),
    );

    if (res) {
      setSearchParams({ step: previous.toString() });
      setStep(prev => prev - 1);
    }

    setPreviousLoading(false);
  };

  const handleNext = async state => {
    if (mode === 'view') {
      setStep(prev => prev + 1);
      return;
    }

    const is1stStep = step === 1;
    const next = step + 1;
    const isCompleted = step === constants.maxStep;

    setNextLoading(true);
    const res = await dispatch(
      manageProperty({
        data: {
          ...(mode === 'add' ? {} : { id }),
          data: {
            ...state,
            step: isCompleted ? step : next,
            isCompleted,
          },
        },
      }),
    );

    if (res) {
      if (isCompleted) {
        navigate('/app');
      } else {
        setSearchParams({ step: next.toString() });
        if (is1stStep) navigate(`/app/owner/property/edit/${res.data.data._id}`);
        setStep(prev => prev + 1);
      }
    }

    setNextLoading(false);
  };

  const buttons = ({ state }) => (
    <div className="step-buttons">
      <div>
        <Button onClick={handleHome}>Home</Button>
      </div>
      <div>
        {mode !== 'add' && (
          <Button
            className="me-3"
            loading={previousLoading}
            disabled={step === constants.minStep}
            onClick={() => handlePrevious(state)}
          >
            Previous
          </Button>
        )}
        {!(mode === 'view' && step === constants.maxStep) && (
          <Button loading={nextLoading} onClick={() => handleNext(state)}>
            {step === constants.maxStep ? 'Complete' : 'Next'}
          </Button>
        )}
      </div>
    </div>
  );

  if (loader) return <Loader />;

  const Step = steps[step];

  return (
    <Layout id="manage-property-page">
      <Step Buttons={buttons} viewOnly={mode === 'view'} />
    </Layout>
  );
};

ManageProperty.propTypes = {
  mode: PropTypes.oneOf(['add', 'view', 'edit']).isRequired,
};

export default ManageProperty;
