import React from 'react';

import { Link } from 'react-router-dom';

const ComingSoon = () => (
  <div
    className="static"
    style={{
      backgroundImage: 'url(/img/construction-design-project-renovation-concept.webp)',
      backgroundSize: 'cover',
    }}
  >
    <div>
      <div className="container">
        <div className="row">
          <div className="col-4" />
          <div className="col-md-8 col-12 my-5 thank_you_card text-center">
            <h2 className="mt-5">We&apos;re Sorry!</h2>
            <h2 className="mt-5 ">This page is down for maintenance.</h2>

            <h4 className="mt-5">
              We are working to get it back up and running as soon as possible.
            </h4>
            <h4 className="mt-5">Please check back!</h4>
            <Link to="/#contact" className="btn btn-success">
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ComingSoon;
