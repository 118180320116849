import React, { useState, useEffect } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Loader from './components/shared/Loader';
import { loadUser } from './redux/actions/authActions';
import store from './redux/store';
import router from './Routing';
import './styles/common.css';
import './styles/custom.scss';
import './styles/main.scss';

const App = () => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      await store.dispatch(loadUser());
      setLoaded(true);
    })();
  }, []);

  if (!isLoaded) return <Loader />;

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ToastContainer />
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
    </Provider>
  );
};

export default App;
