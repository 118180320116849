import React from 'react';

import FAQs from '../components/home/faqs';
import Layout from '../layouts/Layout';

const About = () => (
  <Layout id="about" header noFluid>
    <div className="static header-height">
      <div className="container">
        <h1 className="text-center title no-margin">
          <span>WHO WE ARE</span>
        </h1>
      </div>
      <div className="about-container" style={{ backgroundImage: "url('/img/about-img.png')" }}>
        <div className="container">
          <p className="text-center text-light py-5">
            Wizitcan Solutions is an online platform and an upcoming mobile application that
            connects immigrants who are going to Canada for University, work, and settlement and are
            looking for homestays and employment opportunities within the local communities. People
            already in Canada would also be able to provide specific on-site information about the
            area&apos;s locality and essentials that incoming immigrants would like to learn about.
            <br />
            <br />
            The platform would also include an open discussion and sharing board for everyone to
            support each other for any issues faced by the community.
            <br />
            <br />
            People moving to Canada can search for and view profiles and locations in cities across
            Canada that have extra rooms and want to rent them out at affordable rates. By doing so,
            they will also support people from their native homeland who may struggle with
            homesickness and culture shock and may not know what services are available to them in
            Canada.
            <br />
            <br />
            The newcomers will be able to make the transition into Canadian culture much easier with
            the support of a local community. They will have the opportunity to earn some extra
            money by finding a job within the business community in Canada. Furthermore, by
            providing a way for incomers coming to Canada to connect with people already in Canada,
            they can receive peer support to further help with the transition.
            <br />
            <br />
            Sellers will be able to upload and manage their profiles and status of available
            homestay or employment opportunities, offering their mentorship services; they will be
            able to manage their profile and availability via a live messenger program that shows if
            or when they will be online and available.
          </p>
        </div>
      </div>

      <div className="container">
        <h1 className="text-center title">
          <span>About The Founder</span>
        </h1>
      </div>
      <div
        className="abt-founder-container p-5"
        style={{ backgroundImage: "url('/img/abt-founder.png')" }}
      >
        <div className="founder-content container">
          <p>
            Vikas Khanna - the founder and CEO of Wizitcan Solutions, is a successful entrepreneur
            with strong experience in hospitality and customer-focused services. He is highly
            skilled and motivated, ready to learn every day, with over 17 years of extrinsic
            experience focusing on customer service-centered business.
            <br />
            <br />
            With a dual master’s degree in Business Administration and Economics, coupled with his
            skills and direct marketing experiences, Vikas has helped formulate a well-researched
            business plan to help incoming immigrants coming to Canada to help them find
            accommodation and Jobs within the local Canadian communities.
            <br />
            <br />
            With a vast knowledge of the market and its need, he served as the Field Research
            Officer of the multinational corporation – AC Nielsen, a global measurement and data
            analytics company when technology-driven solutions were not readily available. Not only
            this, but he also holds strong hands in consolidating businesses. In 2017, he merged his
            well-run health club into and within a hotel he now owns and manages. Having witnessed
            the challenges of immigration as a father when both his children went to Canada for
            their higher education, his marketing research skills and the challenges faced by his
            children have helped him successfully formulate and execute his role at – Wizitcan
            Solutions as the CEO.
          </p>
        </div>
      </div>

      <FAQs />
    </div>
  </Layout>
);

export default About;
