import React, { useState } from 'react';

import { Carousel, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropertyFacilities from './PropertyFacilities';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';
import { createInquiry, manageShortlist } from '../../redux/actions/propertyActions';
import RecursiveFormList from '../shared/RecursiveFormList';
import Button from '../UI/Button';
import Typography from '../UI/Typography';

const PropertyDetail = () => {
  const dispatch = useDispatch();
  const constants = useSelector(state => state.property.constants);
  const property = useSelector(state => state.property.property);

  const [isShortlisted, setShortlist] = useState(property.isShortlisted);
  const [inquiryLoading, setInquiryLoading] = useState(false);

  const [{ rent: price }] = property.floorPlans.slice().sort((a, b) => a.rent - b.rent);

  useUpdateEffect(() => {
    (async () => {
      await dispatch(
        manageShortlist({
          data: { id: property._id, isAdded: isShortlisted },
        }),
      );
    })();
  }, [dispatch, property._id, isShortlisted]);

  const getImages = () => {
    const [firstImg, secondImg, thirdImg] = property.images;
    const demoImg = '/dummy.jpeg';

    return {
      firstImage: firstImg?.url ?? demoImg,
      secondImage: secondImg?.url ?? demoImg,
      thirdImage: thirdImg?.url ?? demoImg,
      images: (property.images ?? [])
        .slice()
        .reverse()
        .map((img, index) => ({ id: index + 1, ...img })),
    };
  };

  const handleShare = async () => {
    await navigator.clipboard.writeText(window.location.href);
    toast.success('URL Copied', { autoClose: 2000 });
  };

  const handleInquiry = async () => {
    setInquiryLoading(true);

    const res = await dispatch(
      createInquiry({
        data: { property: property._id },
      }),
    );

    toast.success(res.data.message);
    setInquiryLoading(false);
  };

  const options = [
    {
      id: 1,
      title: {
        text: 'Description',
      },
      details: [
        {
          id: 1.1,
          input: {
            text: property.description,
            mb: 0,
          },
        },
      ],
    },
    {
      id: 2,
      title: {
        text: 'What will you get',
      },
      details: [
        {
          id: 2.1,
          typography: {
            text: 'Utilities included',
            icon: 'plug',
          },
          list: {
            reduxState: constants.utilities,
            state: property.utilities,
          },
        },
        {
          id: 2.2,
          typography: {
            text: 'Building Features',
            icon: 'building',
          },
          list: {
            reduxState: constants.buildingFeatures,
            state: property.buildingFeatures,
          },
        },
        {
          id: 2.3,
          typography: {
            text: 'Unit Features',
            icon: 'hr',
          },
          list: {
            reduxState: constants.unitFeatures,
            state: property.unitFeatures,
          },
        },
        {
          id: 2.4,
          typography: {
            text: 'Nearby Places',
            icon: 'shop',
          },
          list: {
            reduxState: constants.nearbyPlaces,
            state: property.nearbyPlaces,
          },
        },
      ],
    },
    {
      id: 3,
      title: {
        text: `Room Types (${property.floorPlans.length})`,
      },
      details: property.floorPlans.map(floor => ({
        id: floor.id,
        typography: {
          text: `${floor.bedroom} Bedroom and ${floor.bathroom} Bathroom`,
        },
        input: {
          text: (
            <div className="floor-block">
              <div className="floor-plan">
                <span>Price: </span>
                <span>${floor.rent}</span>
              </div>
              <div className="floor-plan">
                <span>Unit Size: </span>
                <span>{floor.unitSize}</span>
              </div>
              <div className="floor-plan">
                <span>Available from: </span>
                <span>{new Date(floor.availabilityDate).toLocaleDateString()}</span>
              </div>
            </div>
          ),
          mb: 0,
        },
      })),
    },
  ];

  const { firstImage, secondImage, thirdImage, images } = getImages();

  return (
    <Row className="property-detail">
      <Col lg={8} className="content-block">
        <div className="images-container">
          <Row className="images-block gx-3">
            <Col md={8} className="left">
              <img className="img" src={firstImage} alt="first" />
            </Col>
            <Col md={4} className="right">
              <img className="img" src={secondImage} alt="second" />
              <img className="img" src={thirdImage} alt="third" />
              <Carousel fade interval={5000} controls={false}>
                {images.map(img => (
                  <Carousel.Item key={img.id}>
                    <img src={img.url} alt={img.path} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
          <div className="detail">
            <div className="name">
              <Typography text={`${property.address2}, ${property.city}`} subtitle mb={0} />
            </div>
            <div className="area">
              <Typography
                text={`${property.address1}, ${property.city}`}
                icon="geo-alt"
                subtitle
                mb={0}
              />
            </div>
            <PropertyFacilities {...property} />
          </div>
        </div>
        <div className="options-container">
          {options.map(({ id, title, details }) => (
            <div key={id} className="gray-box">
              <Typography {...title} subtitle mb={0} />
              {details.map(({ id: detailId, typography, list, input }) => (
                <div key={detailId} className="gray-internal-box">
                  {!!typography && <Typography {...typography} />}
                  {!!list && <RecursiveFormList {...list} setState={() => null} selectedOnly />}
                  {!!input && <Typography {...input} subtitle />}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Col>
      <Col lg={4} className="inquiry-block">
        <div className="gray-box">
          <div className="price-block">
            <span className="price-text">Starting from ${price}/month</span>
            <i
              role="presentation"
              className={`icon bi ${isShortlisted ? 'bi-heart-fill' : 'bi-heart'}`}
              onClick={() => setShortlist(bool => !bool)}
            />
          </div>
          <div className="facility-block">
            <div className="popular-block">
              <Typography text="Popular" icon="fire" subtitle mb={1} />
            </div>
            <PropertyFacilities {...property} />
          </div>
          <div className="buttons-block">
            <Button onClick={handleShare}>Share</Button>
            <Button onClick={handleInquiry} loading={inquiryLoading}>
              Inquire Now
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PropertyDetail;
