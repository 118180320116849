import { Country, State, City } from 'country-state-city';

const getCountries = () => Country.getAllCountries();

export const getCountryOptions = () => {
  const defaultSelection = { name: 'Select Country', isoCode: '', disabled: true };
  const countries = [defaultSelection, ...getCountries()];

  return countries.map(cur => ({
    label: cur.name,
    value: cur.isoCode,
    disabled: cur.disabled ?? false,
  }));
};

const getStates = countryCode => {
  const states = State.getStatesOfCountry(countryCode);

  if (states.length) return states;
  if (!countryCode) return [];

  const state = Country.getCountryByCode(countryCode);
  return [{ name: state.name, isoCode: state.isoCode }];
};

export const getStateOptions = countryCode => {
  const defaultSelection = { name: 'Select State', isoCode: '', disabled: true };
  const states = [defaultSelection, ...getStates(countryCode)];

  return states.map(cur => ({
    label: cur.name,
    value: cur.isoCode,
    disabled: cur.disabled ?? false,
  }));
};

const getCities = (countryCode, stateCode) => {
  const cities = City.getCitiesOfState(countryCode, stateCode);

  if (cities.length) return cities;
  if (!(countryCode && stateCode)) return [];

  const state =
    State.getStateByCodeAndCountry(stateCode, countryCode) ?? Country.getCountryByCode(countryCode);
  return [{ name: state.name, isoCode: state.isoCode }];
};

export const getCityOptions = (countryCode, stateCode) => {
  const defaultSelection = { name: 'Select City', isoCode: '', disabled: true };
  const cities = [defaultSelection, ...getCities(countryCode, stateCode)];

  return cities.map(cur => ({
    label: cur.name,
    value: cur.isoCode,
    disabled: cur.disabled ?? false,
  }));
};
