import React from 'react';

import PropTypes from 'prop-types';
import RBModal from 'react-bootstrap/Modal';

const Modal = props => {
  const { children, visible, onClose, title, Footer } = props;

  return (
    <RBModal show={visible} onHide={onClose} size="lg" centered>
      <RBModal.Header closeButton>
        <RBModal.Title>{title}</RBModal.Title>
      </RBModal.Header>
      <RBModal.Body>{children}</RBModal.Body>
      {Footer ? (
        <RBModal.Footer>
          <Footer {...props} />
        </RBModal.Footer>
      ) : null}
    </RBModal>
  );
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  Footer: PropTypes.func.isRequired,
};

export default Modal;
