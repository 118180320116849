import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import RecursiveFormList from '../shared/RecursiveFormList';
import Checkbox from '../UI/Checkbox';
import Input from '../UI/Input';
import Select from '../UI/Select';
import Typography from '../UI/Typography';

const PropertyStep2 = ({ Buttons, viewOnly }) => {
  const {
    utilities: utilitiesData,
    categories: categoriesData,
    furnishedList: furnishedListData,
    leaseTerms: leaseTermsData,
    parkingTypes: parkingTypesData,
    parkingSpots: parkingSpotsData,
  } = useSelector(state => state.property.constants);
  const property = useSelector(state => state.property.property);
  const [utilities, setUtilities] = useState(property.utilities);
  const [categories, setCategories] = useState(property.categories);
  const [builtYear, setBuiltYear] = useState(property.builtYear);
  const [isPetFriendly, setPetFriendly] = useState(property.isPetFriendly);
  const [isDoingSmoking, setSmoking] = useState(property.isDoingSmoking);
  const [furnishedList, setFurnishedList] = useState(property.furnishedList);
  const [leaseTerms, setLeaseTerms] = useState(property.leaseTerms);
  const [parkingTypes, setParkingTypes] = useState(property.parkingTypes);
  const [parkingSpots, setParkingSpots] = useState(property.parkingSpots);
  const [description, setDescription] = useState(property.description);

  return (
    <Fragment>
      <div className="step step-2" style={{ pointerEvents: viewOnly ? 'none' : 'visible' }}>
        <div className="step-wrapper">
          <Typography text="Property details" mb={1} />
          <Typography
            text="Please provide important information about your rental property so that its easier for the renter."
            subtitle
            mb={0}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Utilities included" icon="plug" />
          <RecursiveFormList reduxState={utilitiesData} state={utilities} setState={setUtilities} />
        </div>
        <div className="step-wrapper">
          <Typography text="Category" icon="check2-square" />
          <RecursiveFormList
            reduxState={categoriesData}
            state={categories}
            setState={setCategories}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Lease term" icon="clock-history" />
          <RecursiveFormList
            reduxState={leaseTermsData}
            state={leaseTerms}
            setState={setLeaseTerms}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Year Built" icon="hammer" />
          <Select
            value={builtYear}
            onChange={e => setBuiltYear(e.target.value)}
            options={[
              { label: 'Select Year', value: undefined, disabled: true },
              ...[...Array(50)].reduce((acc, _, i) => {
                const year = !acc.length ? new Date().getFullYear() : acc[i - 1].value - 1;
                acc.push({ label: year, value: year });
                return acc;
              }, []),
            ]}
          />
        </div>
        <div className="step-wrapper flex-wrapper">
          <Typography text="Pet Friendly" svg="/pet.svg" />
          <div className="recursive-root">
            {[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ].map(cur => (
              <Checkbox
                key={`pet-${cur.label}`}
                id={`pet-${cur.label}`}
                type="radio"
                label={cur.label}
                checked={isPetFriendly === cur.value}
                value={cur.value}
                onChange={e => setPetFriendly(JSON.parse(e.target.value))}
              />
            ))}
          </div>
        </div>
        <div className="step-wrapper flex-wrapper">
          <Typography text="Smoking" svg="/smoking.svg" />
          <div className="recursive-root">
            {[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ].map(cur => (
              <Checkbox
                key={`smoking-${cur.label}`}
                id={`smoking-${cur.label}`}
                type="radio"
                label={cur.label}
                checked={isDoingSmoking === cur.value}
                value={cur.value}
                onChange={e => setSmoking(JSON.parse(e.target.value))}
              />
            ))}
          </div>
        </div>
        <div className="step-wrapper">
          <Typography text="Furnished" icon="bricks" />
          <RecursiveFormList
            reduxState={furnishedListData}
            state={furnishedList}
            setState={setFurnishedList}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Parking Type" icon="p-square" />
          <RecursiveFormList
            reduxState={parkingTypesData}
            state={parkingTypes}
            setState={setParkingTypes}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Parking spot" icon="car-front-fill" />
          <RecursiveFormList
            reduxState={parkingSpotsData}
            state={parkingSpots}
            setState={setParkingSpots}
          />
        </div>
        <div className="step-wrapper">
          <Typography text="Description of the property" />
          <Input
            as="textarea"
            cols={50}
            rows={3}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </div>

      <Buttons
        state={{
          utilities,
          categories,
          builtYear,
          isPetFriendly,
          isDoingSmoking,
          furnishedList,
          leaseTerms,
          parkingTypes,
          parkingSpots,
          description,
        }}
      />
    </Fragment>
  );
};

PropertyStep2.propTypes = {
  Buttons: PropTypes.node.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default PropertyStep2;
