import React, { Fragment, useCallback, useState } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../components/UI/Button';
import Input from '../components/UI/Input';
import Modal from '../components/UI/Modal';
import Select from '../components/UI/Select';
import Layout from '../layouts/Layout';
import { login, signup, socialAuth } from '../redux/actions/authActions';

const OwnerSelect = props => (
  <Form.Group className="mb-3">
    <Select
      {...props}
      className="w-100"
      options={[
        { label: 'I am a Renter', value: 2 },
        { label: 'I am a Property Owner', value: 1 },
      ]}
    />
  </Form.Group>
);

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [isLogin, setLogin] = useState(params.type === 'login');
  const [fields, setFields] = useState({
    fullName: '',
    email: '',
    password: '',
    ownerType: 2,
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAccepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState({});
  const [credential, setCredential] = useState(null);
  const [isInformationModalVisible, setInformationModal] = useState(false);
  const [informationLoading, setInformationLoading] = useState(false);

  const handleChange = (key, value) => {
    setFields(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleInformationPopup = cred => {
    setCredential(cred);
    setInformationModal(true);
  };

  const handleGoogleLogin = async cred => {
    setInformationLoading(true);
    const res = await dispatch(
      socialAuth({
        data: {
          ownerType: credential ? fields.ownerType : undefined,
          credential: credential ?? cred,
        },
      }),
    );
    setInformationLoading(false);

    if (res === null) {
      handleInformationPopup(cred);
      return;
    }

    if (res) {
      toast.success(res.data.message);
      navigate('/#wizitcanworks');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) return;

    if (!isLogin && fields.password !== confirmPassword) {
      setError(prev => ({ ...prev, confirmPassword: true }));
      return;
    }

    setError({});
    setLoading(true);

    const call = isLogin ? login : signup;
    const res = await dispatch(
      call({ data: isLogin ? { email: fields.email, password: fields.password } : fields }),
    );

    if (res) {
      toast.success(res.data.message);
      navigate('/#wizitcanworks');
    }

    setLoading(false);
  };

  const footer = useCallback(
    ({ onSubmit, onClose }) => (
      <Fragment>
        <Button onClick={onClose} size="sm">
          Close
        </Button>
        <Button onClick={onSubmit} size="sm" loading={informationLoading}>
          Submit
        </Button>
      </Fragment>
    ),
    [informationLoading],
  );

  return (
    <Layout id="auth-page" header={false}>
      <div className="wrapper" style={{ backgroundImage: "url('/auth-wallpaper.jpg')" }}>
        <div className="auth-form">
          <Form className="text-white" onSubmit={handleSubmit}>
            <h2 className="mb-4 text-center">{isLogin ? 'Login' : 'Create Account'}</h2>
            {!isLogin && (
              <Form.Group className="mb-3">
                <Input
                  type="text"
                  placeholder="Full Name"
                  value={fields.fullName}
                  onChange={e => handleChange('fullName', e.target.value)}
                  required
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Input
                type="email"
                placeholder="Email"
                value={fields.email}
                onChange={e => handleChange('email', e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input
                type="password"
                placeholder="Password"
                minLength={4}
                value={fields.password}
                onChange={e => handleChange('password', e.target.value)}
                required
              />
            </Form.Group>
            {!isLogin && (
              <Fragment>
                <InputGroup className="mb-3" hasValidation>
                  <Input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    minLength={4}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    required
                    isInvalid={!!error.confirmPassword}
                  />
                  <Button
                    className="py-0 px-3"
                    variant="primary"
                    onClick={() => setPasswordVisible(prev => !prev)}
                  >
                    <i className={`bi bi-eye${passwordVisible ? '-slash' : ''}`} />
                  </Button>
                  <Form.Control.Feedback type="invalid" tooltip>
                    Confirm password not matched with password.
                  </Form.Control.Feedback>
                </InputGroup>

                <OwnerSelect
                  value={fields.ownerType}
                  onChange={e => handleChange('ownerType', +e.target.value)}
                />

                <Form.Group className="mb-3">
                  <Form.Check
                    id="terms-conditions"
                    type="checkbox"
                    label="Accept our Terms & Conditions"
                    checked={isAccepted}
                    onChange={e => setAccepted(e.target.checked)}
                    required
                  />
                </Form.Group>
              </Fragment>
            )}
            <Button className="fw-bolder w-100" variant="primary" type="submit" loading={loading}>
              {isLogin ? 'Login' : 'Register'}
            </Button>
            <h6 className="or-line">
              <span>or {isLogin ? 'login' : 'register'} with social media</span>
            </h6>
            <div className="social-auth">
              <GoogleLogin
                onSuccess={response => handleGoogleLogin(response.credential)}
                text={isLogin ? 'signin_with' : 'signup_with'}
                auto_select={false}
              />
            </div>
            <div className="mt-3 text-center">
              <span>{isLogin ? 'Want to create an account?' : 'Already have an account?'}</span>
              <span
                role="presentation"
                className="ms-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => setLogin(prev => !prev)}
              >
                {isLogin ? 'Sign up' : 'Sign in'}
              </span>
            </div>
          </Form>
        </div>
      </div>

      {isInformationModalVisible && (
        <Modal
          visible={isInformationModalVisible}
          onClose={() => setInformationModal(false)}
          title="Fill required information"
          Footer={({ onClose }) => footer({ onClose, onSubmit: handleGoogleLogin })}
        >
          <p>Before continue, please provide required information</p>
          <OwnerSelect
            value={fields.ownerType}
            onChange={e => handleChange('ownerType', +e.target.value)}
          />
        </Modal>
      )}
    </Layout>
  );
};

export default Auth;
