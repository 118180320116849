import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { addPropertyImage, deletePropertyImage } from '../../redux/actions/propertyActions';
import { floorState, modifyFloorPlans } from '../../redux/reducers/propertyReducer';
import Button from '../UI/Button';
import Input from '../UI/Input';
import Select from '../UI/Select';
import Typography from '../UI/Typography';

const PropertyStep4 = ({ Buttons, viewOnly }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const property = useSelector(state => state.property.property);
  const [floorPlans, setFloorPlans] = useState(property.floorPlans);

  const handleAdd = () => {
    setFloorPlans(prev => [...prev, { ...floorState, id: Math.random().toString() }]);
  };

  const handleRemove = id => {
    setFloorPlans(prev => prev.filter(cur => cur.id !== id));
  };

  const handleChange = (id, key, value) => {
    setFloorPlans(prev => prev.map(cur => (cur.id === id ? { ...cur, [key]: value } : cur)));
  };

  const handleFile = async e => {
    const formData = new FormData();
    formData.append('id', params.id);
    [...e.target.files].forEach(file => formData.append('images', file));

    return dispatch(addPropertyImage({ data: formData }));
  };

  const handleImageRemove = async id => {
    await dispatch(
      deletePropertyImage({
        data: {
          id: params.id,
          imageId: id,
        },
      }),
    );
  };

  return (
    <Fragment>
      <div className="step step-3" style={{ pointerEvents: viewOnly ? 'none' : 'visible' }}>
        <div className="step-wrapper">
          <Typography text="Floor Plans" mb={1} />
          <Typography
            text="Your rental requires at least one floor plan, but feel free to add more"
            subtitle
            mb={0}
          />
        </div>
        <div className="floor-plans step-wrapper">
          {floorPlans.map(floor => (
            <div key={floor.id} className="plans">
              {!viewOnly && floorPlans.length > 1 && (
                <i
                  role="presentation"
                  className="floor-delete bi bi-x-square"
                  onClick={() => handleRemove(floor.id)}
                />
              )}
              <div className="plan">
                <p>Bedroom</p>
                <Select
                  value={floor.bedroom}
                  onChange={e => handleChange(floor.id, 'bedroom', +e.target.value)}
                  options={[
                    { label: 'Select Number', value: undefined },
                    ...[...Array(10)].map((_, i) => ({ label: i + 1, value: i + 1 })),
                  ]}
                />
              </div>
              <div className="plan">
                <p>Bathroom</p>
                <Select
                  value={floor.bathroom}
                  onChange={e => handleChange(floor.id, 'bathroom', +e.target.value)}
                  options={[
                    { label: 'Select Number', value: undefined },
                    ...[...Array(10)].map((_, i) => ({ label: i + 1, value: i + 1 })),
                  ]}
                />
              </div>
              <div className="plan">
                <p>Rent</p>
                <Input
                  value={floor.rent}
                  onChange={e => handleChange(floor.id, 'rent', e.target.value)}
                />
              </div>
              <div className="plan">
                <p>Unit Size</p>
                <Input
                  value={floor.unitSize}
                  onChange={e => handleChange(floor.id, 'unitSize', e.target.value)}
                />
              </div>
              <div className="plan">
                <p>Availability date</p>
                <Input
                  type="date"
                  value={floor.availabilityDate}
                  onChange={e => handleChange(floor.id, 'availabilityDate', e.target.value)}
                />
              </div>
            </div>
          ))}
          {!viewOnly && (
            <div className="plans add-floor">
              <Button onClick={handleAdd}>+ Add Floor</Button>
            </div>
          )}
        </div>
        <div className="step-wrapper">
          <Typography text="Images" icon="images" mb={1} />
          <Typography text="Add photos for your renter to see in JPG or PDF format" subtitle />
          <div className="step-images">
            {(property.images ?? []).map(cur => (
              <div key={cur._id} className="position-relative">
                {cur.mimetype.startsWith('image/') ? (
                  <img src={cur.url} alt="property" />
                ) : (
                  <div
                    role="presentation"
                    className="pdf-block"
                    onClick={() => window.open(cur.url, '_blank')}
                  >
                    Open PDF
                  </div>
                )}
                {!viewOnly && (
                  <div
                    role="presentation"
                    className="step-close"
                    onClick={() => handleImageRemove(cur._id)}
                  >
                    <i className="bi bi-x-square" />
                  </div>
                )}
              </div>
            ))}
          </div>
          {!viewOnly && (
            <Input
              multiple
              type="file"
              accept="image/*, application/pdf"
              value=""
              onChange={handleFile}
            />
          )}
        </div>
      </div>
      <Buttons state={{ floorPlans: modifyFloorPlans(floorPlans) }} />
    </Fragment>
  );
};

PropertyStep4.propTypes = {
  Buttons: PropTypes.node.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default PropertyStep4;
