import ApiUtils from '../../api/APIUtils';

const api = new ApiUtils();

const { REACT_APP_TOKEN_NAME: LS_TOKEN_NAME, REACT_APP_AUTH_HEADER_NAME: HEADER_NAME } =
  process.env;

export const loadUser = () => async dispatch => {
  try {
    const token = localStorage.getItem(LS_TOKEN_NAME);

    if (!token) return dispatch({ type: 'NEW_USER' });

    const res = await api.loadUser({ headers: { [HEADER_NAME]: `Bearer ${token}` } });

    dispatch({
      type: 'EXISTING_USER',
      payload: res.data.data,
      token,
    });

    return res;
  } catch (err) {
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const signup = data => async dispatch => {
  try {
    const res = await api.signup(data);

    localStorage.setItem(LS_TOKEN_NAME, res.data.token);

    await dispatch(loadUser());

    return res;
  } catch (err) {
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const login = data => async dispatch => {
  try {
    const res = await api.login(data);

    localStorage.setItem(LS_TOKEN_NAME, res.data.token);

    await dispatch(loadUser());

    return res;
  } catch (err) {
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const socialAuth = data => async dispatch => {
  try {
    const res = await api.socialAuth(data);

    localStorage.setItem(LS_TOKEN_NAME, res.data.token);

    await dispatch(loadUser());

    return res;
  } catch (err) {
    if (err.response.data.ownerTypeNeeded) return null;
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const logout = () => async dispatch => {
  try {
    localStorage.removeItem(LS_TOKEN_NAME);
    dispatch({ type: 'LOGOUT' });

    return true;
  } catch (err) {
    console.log('CATCH ERROR', err);
    return false;
  }
};
