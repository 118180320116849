import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ApiUtils from '../../api/APIUtils';

const api = new ApiUtils();

const ContactUs = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    await api.createContact({ data: { name, email, number, message } });
    navigate(`/contact-success/${name}`);
  };

  return (
    <div
      className="contact-us"
      id="contact"
      style={{ backgroundImage: "url('/img/contact-img.png')" }}
    >
      <div className="container mt-5">
        <h1 className="title text-center">
          Get In <span>Touch</span>
        </h1>
        <h6 className="mt-4 text-center">
          <i className="bi bi-envelope" /> Email: wizitcansolutions@gmail.com
        </h6>

        <form onSubmit={handleSubmit}>
          <div className="row g-5">
            <div className="col-md-6 pt-5">
              <input
                className="mt-3"
                name="name"
                type="text"
                required
                placeholder="Your Name:"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input
                className="mt-4"
                name="email"
                type="email"
                required
                placeholder="Your Email:"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <input
                className="mt-4"
                name="number"
                type="tel"
                required
                placeholder="Your Phone:"
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
            </div>
            <div className="col-md-6 pt-5">
              <textarea
                className="mt-3"
                name="message"
                required
                placeholder="Message:"
                id=""
                cols="25"
                rows="7"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="text-center">
            <button style={{ borderRadius: 0 }} className="text0center btn cta-btn" type="submit">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
