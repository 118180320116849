import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import Header from '../components/shared/Header';

const Layout = ({ children, id, header = true, noFluid = false }) => (
  <div className="layout">
    {header && <Header />}
    <main
      id={id}
      className={clsx('layout-main', {
        'layout-main-no-fluid': noFluid,
      })}
    >
      {children}
    </main>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  header: PropTypes.bool,
  noFluid: PropTypes.bool,
};

export default Layout;
