import React, { Fragment } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper/modules';
import { Swiper as ReactSwiper, useSwiper } from 'swiper/react';

import { useBreakpoints } from '../../hooks/useBreakpoints';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CustomPagination = () => {
  const swiper = useSwiper();

  return (
    <Fragment>
      <div role="presentation" className="swiper-button-next" onClick={() => swiper.slideNext()}>
        <div className="icon-wrapper">
          <i className="icon bi bi-chevron-right" />
        </div>
      </div>
      <div role="presentation" className="swiper-button-prev" onClick={() => swiper.slidePrev()}>
        <div className="icon-wrapper">
          <i className="icon bi bi-chevron-left" />
        </div>
      </div>
    </Fragment>
  );
};

const Swiper = ({ children, ...props }) => {
  const breakpoints = useBreakpoints();

  return (
    <ReactSwiper
      slidesPerView={1}
      spaceBetween={20}
      navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
      modules={[Navigation]}
      breakpoints={{
        [breakpoints.md]: {
          slidesPerView: 2,
        },
        [breakpoints.lg]: {
          slidesPerView: 3,
        },
        [breakpoints.xl]: {
          slidesPerView: 4,
        },
      }}
      {...props}
      className={clsx('react-swiper', props.className)}
    >
      {children}
      <CustomPagination />
    </ReactSwiper>
  );
};

Swiper.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Swiper;
