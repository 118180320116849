import React, { useEffect, useState } from 'react';

import querystring from 'query-string';
import { Col, Row, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import PropertyCard from '../../components/property/PropertyCard';
import Loader from '../../components/shared/Loader';
import Swiper from '../../components/shared/Swiper';
import Button from '../../components/UI/Button';
import Input from '../../components/UI/Input';
import Typography from '../../components/UI/Typography';
import Layout from '../../layouts/Layout';
import {
  getAllHotPics,
  getAllProperties,
  getAllPropertyCities,
} from '../../redux/actions/propertyActions';

const RenterProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [latestProperties, setLatestProperties] = useState([]);
  const [hotPics, setHotPics] = useState([]);
  const [cities, setCities] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      const [{ data: propertiesData }, { data: hotPicsData }, { data: citiesData }] =
        await Promise.all([
          dispatch(getAllProperties({ params: { page: 1, limit: 8 } })),
          dispatch(getAllHotPics({ params: { page: 1, limit: 8 } })),
          dispatch(getAllPropertyCities()),
        ]);

      setLatestProperties(propertiesData.data.docs);
      setHotPics(hotPicsData.data.docs);
      setCities(citiesData.data);
      setLoader(false);
    })();
  }, [dispatch]);

  const handleSearch = async filter => {
    const searchString = querystring.stringify({ search: filter });
    navigate(`listing?${searchString}`);
  };

  const handleInput = async e => {
    const { code } = e;

    if (code === 'Enter') await handleSearch(search);
  };

  if (loader) return <Loader />;

  return (
    <Layout id="property-renter-page" noFluid>
      <div className="search-container">
        <div className="image-block">
          <img src="/test.webp" alt="search" />
        </div>
        <div className="search-block">
          <InputGroup className="search-group">
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Search by City or State"
              onKeyUp={handleInput}
            />
            <Button onClick={() => handleSearch(search)}>
              <div className="icon-wrapper">
                <i className="icon bi bi-search" />
              </div>
            </Button>
          </InputGroup>
        </div>
      </div>
      <div className="content-container">
        <div className="latest-block">
          <Typography text="Latest" icon="bar-chart-fill" />
          <Swiper className="latest-block-swiper">
            {latestProperties.map(property => (
              <SwiperSlide key={property._id}>
                <PropertyCard {...property} vertical />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="recently-viewed-block">
          <Typography text="Hot Picks" icon="fire" />
          <Swiper className="recently-viewed-swiper">
            {hotPics.map(property => (
              <SwiperSlide key={property._id}>
                <PropertyCard {...property} vertical />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="cities-block">
          <Typography text="Popular Cities" icon="building" />
          <Row className="cities-container gy-4">
            {cities.map(city => (
              <Col key={city} xs={3}>
                <Button className="w-100" onClick={() => handleSearch(city)}>
                  {city}
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default RenterProperty;
