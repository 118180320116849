import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

const ContactSuccess = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setTimeout(() => navigate(`/`), 5000);
  }, [navigate]);

  return (
    <div className="static" style={{ backgroundImage: 'url(/img/success.jpeg)', height: '80vh' }}>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-4" />
            <div className="col-md-8 col-12 my-5 thank_you_card text-center">
              <h2 className="mt-5">DEAR {params.name}</h2>
              <h2 className="mt-5 ">WE ARE THRILLED TO HERE FROM YOU!</h2>
              <h2 className="mt-5 ">THANK FOR CONTACTING US.</h2>
              <h4 className="mt-5">
                We are reviewing your request <br /> and will get in touch as soon <br />
                as possible
              </h4>
              <h4 className="mt-5">THANK YOU!</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSuccess;
