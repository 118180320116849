import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import RBButton from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const Button = ({ children, loading = false, ...rest }) => (
  <RBButton
    {...rest}
    className={clsx(rest.className, 'ui-button')}
    disabled={rest.disabled || !!loading}
  >
    {loading && <Spinner className="ui-button-spinner" as="span" animation="border" size="sm" />}
    {children}
  </RBButton>
);

Button.propTypes = {
  loading: PropTypes.bool,
};

export default Button;
