import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PropertyDetail from '../../components/property/PropertyDetail';
import Loader from '../../components/shared/Loader';
import Layout from '../../layouts/Layout';
import { getProperty } from '../../redux/actions/propertyActions';

const PropertyDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [loader, setLoader] = useState(true);

  const { id } = params;

  useEffect(() => {
    (async () => {
      const res = await dispatch(getProperty(id));

      if (!res) {
        navigate('/app');
        return;
      }

      setLoader(false);
    })();
  }, [dispatch, id, navigate]);

  if (loader) return <Loader />;

  return (
    <Layout id="property-renter-detail-page">
      <PropertyDetail />
    </Layout>
  );
};

export default PropertyDetailPage;
