import React from 'react';

const Loader = () => (
  <div className="loader-container">
    <div className="overlay" />
    <div className="wrapper">
      <div className="loader" />
    </div>
  </div>
);

export default Loader;
