import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import PropertyCard from '../../components/property/PropertyCard';
import Loader from '../../components/shared/Loader';
import Layout from '../../layouts/Layout';
import { getAllProperties } from '../../redux/actions/propertyActions';

const RenterPropertyListing = ({ shortlists = false }) => {
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      const { data } = await dispatch(
        getAllProperties(
          {
            data: {
              search: searchParams.get('search') ?? '',
              fields: 'city, state',
              pagination: false,
            },
          },
          shortlists,
        ),
      );
      setLoader(false);
      setProperties(data.data.docs);
    })();
  }, [dispatch, shortlists]);

  if (loader) return <Loader />;

  return (
    <Layout id="property-renter-listing-page">
      {properties.length ? (
        <Row>
          <Col lg={8} className="listing-container">
            {properties.map(cur => (
              <PropertyCard key={cur._id} {...cur} vertical={false} />
            ))}
          </Col>
          {/* <Col lg={4} className="d-none d-lg-block map-container"> */}
          {/*  <iframe */}
          {/*    title="map" */}
          {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9916261109483!2d2.291906376836718!3d48.85837007133214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1700071616833!5m2!1sen!2sin" */}
          {/*    width="100%" */}
          {/*    height="100%" */}
          {/*    style={{ border: 0 }} */}
          {/*    allowFullScreen="" */}
          {/*    loading="lazy" */}
          {/*    referrerPolicy="no-referrer-when-downgrade" */}
          {/*  /> */}
          {/* </Col> */}
        </Row>
      ) : (
        <p>No Properties Found</p>
      )}
    </Layout>
  );
};

RenterPropertyListing.propTypes = {
  shortlists: PropTypes.bool.isRequired,
};

export default RenterPropertyListing;
