import React, { Fragment } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

const Typography = ({ text, subtitle = false, icon = '', svg = '', mb = 2, mt = 0 }) => {
  const Subtitle = subtitle ? 'p' : 'h2';
  const Text = typeof text === 'string' ? Subtitle : 'div';

  return (
    <Fragment>
      {icon && <i className={`ui-typography-icon bi bi-${icon}`} />}
      {svg && <img className="ui-typography-svg" src={svg} alt={svg} />}
      <Text
        style={{ marginBottom: `${mb}rem`, marginTop: `${mt}rem` }}
        className={clsx({
          'ui-typography-title': !subtitle,
          'ui-typography-subtitle': subtitle,
        })}
      >
        {text}
      </Text>
    </Fragment>
  );
};

Typography.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.string,
  svg: PropTypes.string,
  subtitle: PropTypes.bool,
  mb: PropTypes.number,
  mt: PropTypes.number,
};

export default Typography;
