import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const DeciderRoute = () => {
  const ownerTypeName = useSelector(state => state.auth.user.ownerTypeName);

  return <Navigate to={`/app/${ownerTypeName}`} replace />;
};

export default DeciderRoute;
